import React, { Component } from 'react'
import { Link } from 'gatsby'
import he from 'he'

import Fade from 'react-reveal/Fade'

class PostCard extends Component {
  render() {
    let { title, slug, acf } = this.props

    return (
      <div className='card card--post'>
        <Link to={`/news/${slug}`}>
          <figure>
            <Fade bottom distance="0">
              <img src={acf.featured_image && acf.featured_image.localFile && acf.featured_image.localFile.childImageSharp && acf.featured_image.localFile.childImageSharp.original.src} alt={title} />
            </Fade>
          </figure>
          <Fade bottom distance="40px">
            <h4>{he.decode(title)}</h4>
          </Fade>
          <Fade bottom distance="40px">
            <div dangerouslySetInnerHTML={{ __html: acf.excerpt }} />
            <span className='keep-reading'>Keep Reading</span>
          </Fade>
        </Link>
      </div>
    )
  } 
}

PostCard.defaultProps = {
  title: `We are doing ‘it’ better, our way.`,
  slug: `/post`,
  acf: {},
}

export default PostCard
