import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'

import SEO from '../components/seo'
import PostCard from '../components/cards/post'
import Search from '../assets/images/search.svg'

class NewsPage extends Component {

  state = {
    filteredPosts: this.props.data.posts.edges,
    filter: ''
  }

  componentDidMount() {
    const urlSearchParams = window && new URLSearchParams(window.location.search);
    const params = window && Object.fromEntries(urlSearchParams.entries());
    let search = params && params.s
    let input = document.querySelector('.news__search input')
    if (document && search) {
      input.value = search
      input.addEventListener('change', (e) => {
        this._search(e)
      })
      const event = new Event('change')
      input.dispatchEvent(event)
    }
  }

  filterPosts = (filter, set = true) => {
    let filteredPosts = this.props.data.posts.edges
    const categories = this.props.data.allWordpressCategory.edges
    let match = false
    if (filter !== '') {
      filteredPosts = filteredPosts.filter((post) => {
        const categories = post.node.categories
        match = false
        categories.forEach((el, i) => {
          if (el.slug === filter) match = true
        })
        return match
      })
    }

    if (set) {
      this.setState({
        filteredPosts,
        filter
      })
    }

    window && window.scroll({
      behavior: 'smooth',
      top: document && document.querySelector('.news__items').offsetTop
    })

    return filteredPosts
  }

  _search = (event) => {
    let { value } = event.target

    value = value.toLowerCase()

    let { filter } = this.state
    let filteredPosts = this.filterPosts(filter, false)

    filteredPosts = filteredPosts.filter((post) => (post.node.title.toLowerCase().indexOf(value) !== -1 || post.node.acf.excerpt.toLowerCase().indexOf(value) !== -1))

    this.setState({ filteredPosts })
  }

  render() {
    let { posts } = this.props.data
    const categories = this.props.data.allWordpressCategory.edges
    let { filteredPosts, filter } = this.state
    let latest = posts.edges.slice(0, 3);
    return (
      <>
        <SEO title="News and Insights from the FSC Range | Engineering News" description="View the latest news and insights from the FSC Range to learn about project delivery, engineering services, environmental consulting and more. Read more." />
        <section className='breadcrumbs'>
          <div className='breadcrumbs__inner'>
            <ul>
              <li><Link to='/'>Home</Link></li>
              <li>News</li>
            </ul>
          </div>
        </section>
        <section className="news">
          <div className="news__inner container">
            <div className='news__sidebar'>
              <h1>News</h1>
              <div className='news__description'>
                <p>Latest news and insights from the FSC Range.</p>
              </div>
              <form className='news__search'>
                <h4>Search</h4>
                <input type='text' name='s' placeholder='' onChange={this._search} />
                <button>
                  <img src={Search} alt='Search' />
                </button> 
              </form>
              <div className='news__tags'>
                <h4>Popular Tags</h4>
                <ul>
                  <li>
                    <button className={filter === '' ? 'btn btn--active' : 'btn' } onClick={() => this.filterPosts('')}>
                      All
                    </button>
                  </li>
                  { categories && categories.map((el, i) => (
                    <li key={i}>
                      <button className={filter === el.node.slug ? 'btn btn--active' : 'btn' } onClick={() => this.filterPosts(el.node.slug)}>
                        {el.node.name}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              <div className='news__popular'>
                <h4>Popular Articles</h4>
                <ul>
                  { latest.map((el, i) => (
                    <li key={i} {...el.node}><Link to={`/news/${el.node.slug}`}>{el.node.title}</Link></li>
                  )) }
                </ul>
              </div>
            </div>
            <div className='news__items'>
              { filteredPosts && filteredPosts.map((el, i) => (<PostCard key={i} {...el.node} />)) }
            </div>
          </div>
        </section>
      </>
    )
  }
}

export const newsQuery = graphql`
  query {
    posts: allWordpressPost {
      edges {
        node {
          title
          content
          slug
          categories {
            name
            slug
          }
          acf {
            excerpt
            featured_image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    allWordpressCategory {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`

export default NewsPage
